<template>
    <div>
        <h1>home page teacher</h1>
    </div>
</template>

<script>
export default {
    name:'TeacherHome',
}
</script>