
<template>
  <div>
    <!-- <h1>Check  Profile</h1> -->
    <div class="topimgContainer">
      <div class="topimageBox">
        <div id="topheading">
          <div id="heading1">Check Coding Profile</div>
          <div id="headingContent">
         This project is develop to integrate the profile data of leetcode, codechef and codeforces at one place with other more features.
          </div>
        </div>
      </div>
    </div>

    <!-- Content conatiner -->
    <div class="ContentBox">
      <div class="PlatformNameCont">
        <button @click="platform = 'CodeChef'">
          <div
            class="card text-center  colorWhite"
            style="width: 10rem"
          >
            <div class="card-body">
              <h5 class="card-title">CodeChef</h5>
              <!-- <p class="card-text">With supporting</p> -->
            </div>
          </div>
        </button>

        &nbsp; &nbsp; &nbsp;
        <button @click="platform = 'LeetCode'">
          <div
            class="card text-center  colorWhite"
            style="width: 10rem"
          >
            <div class="card-body">
              <h5 class="card-title">Leetcode</h5>
            </div>
          </div>
        </button>
  &nbsp; &nbsp; &nbsp;
        <button @click="platform = 'CodeForces'">
          <div
            class="card text-center colorWhite"
            style="width: 10rem"
          >
            <div class="card-body">
              <h5 class="card-title">Codeforces</h5>
            </div>
          </div>
        </button>

   
        

      
      </div>

      <keep-alive>
        <component :is="platform" />
      </keep-alive>
    </div>


  </div>
</template>


<script>
import CodeChef from '../platform/CodeChef.vue'
import CodeForces from '../platform/CodeForces.vue'
import LeetCode from '../platform/LeetCode.vue'
export default {
  name: "CheckProfileAll",
  components:{
    CodeChef,
    LeetCode,
    CodeForces,
  },
      data(){
        return{
            platform:'CodeChef',
        }
    }
};
</script>




<style>
.topimgContainer,
.PlatformNameCont {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-bottom: 20px;
}

.topimageBox {
  /* border: 2px solid red; */
  height: 650px;
  width: 1530px;
  /* border: 2px solid red; */
  background-image: url("../../assets/home1.png");
  background-repeat: no-repeat;
  background-size: cover;
}
#topheading {
  /* height: 130px; */
  width: 600px;
  /* border: 2px solid red; */
  position: absolute;
  left: 200px;
  top: 200px;
  padding: 20px;
}
#heading1 {
  font-size: 40px;
  font-family: cursive;
  color: white;
  /* background-color: rgb(2, 117, 216); */
  width: fit-content;
  padding-left: 5px;
  padding-right: 5px;
  letter-spacing: 1px;
  margin-bottom: 20px;
}
</style>


<style scoped>
button{
  border: 0px;
  background-color: white;
}

button:focus .card-body{
    background:rgb(196, 192, 192);
   background: #bdc3c7;
  background: -webkit-linear-gradient(to right, #536577, #bdc3c7);
  background: linear-gradient(to right, #536577, #bdc3c7);
  color: white;
}

</style>


