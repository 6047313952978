<template>
    <div>
        <h1>Make Target comp</h1>
    </div>
</template>


<script>
export default {
    name:'MakeTarget'
}
</script>