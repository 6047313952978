<template>
    <div>
        <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
    </div>
</template>

<script>
export default {
    name:'SpinnerComp',
}
</script>