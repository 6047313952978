<template>
    <div>
        <h1>
            Send Question page
        </h1>
    </div>
</template>

<script>
export default {
    name:'SendQuestion'
}
</script>